.editProgramFollowAlongPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    /* display: flex; */
    list-style-type: none;
}

.editProgramFollowAlongPage .mainBody{
    width: 820px;
    margin-top: 50px;
    display: block;
    margin-left: 400px;
    margin-right: auto;
    /* background-color: #ffffff; */
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding-left: 30px; */
}

.editProgramFollowAlongPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px; 
    height: 380px;
    background-color: #FAFAFD;
    border-radius: 10px; 
    z-index: 5;
}

.editProgramFollowAlongPage #modal2{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 200px;
    background-color: #FAFAFD;
    border-radius: 10px; 
    z-index: 5;
    font-family: 'LexendMedium500';
    font-size: 16px;
}
