.addProgramWeeksPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    display: flex;
    list-style-type: none;
}

.addProgramWeeksPage .mainBody{
    width: 700px;
    border-style: solid;
    margin-top: 50px;
    display: block;
    margin-left: 400px;
    margin-right: auto;
    /* background-color: #ffffff; */
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding-left: 30px; */
}