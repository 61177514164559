.usersListPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    display: flex;
    list-style-type: none;
}

.usersListPage .mainBody{
    width: 950px;
    margin-top: 50px;
    margin-left: 330px;
    background-color: #ffffff;
}

input:focus{
    outline: none;
}

table {
    border: 1px solid #D7D4E1;
    width: 940px;
    margin-left: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}


th{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FDF0F4;
    border-width: 0px;
    font-weight: 400;
}

button{
    cursor: pointer;
}

td{
    text-align: center;
    font-family: 'LexendRegular400';
    /* padding-bottom: 10px;
    padding-top: 10px; */
    height: 80px;
    /* background-color: #FAFAFD; */
    
}

.usersListPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 150px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
}