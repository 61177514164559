.previewProgramPage {
  width: 100%;
  height: 100%;
  background-color: #fafafd;
  /* display: flex; */
  list-style-type: none;
}

.previewProgramPage .mainBody {
  width: 820px;
  margin-top: 50px;
  display: block;
  margin-left: 400px;
  margin-right: auto;
  /* background-color: #ffffff; */
  padding-top: 30px;
  padding-bottom: 30px;
  /* padding-left: 30px; */
}

input::placeholder {
  color: #9e9eb4;
  opacity: 1;
  font-family: "LexendRegular400";
  font-size: 14px;
}

textarea::placeholder {
  color: #9e9eb4;
  opacity: 1;
  font-family: "LexendRegular400";
  font-size: 14px;
}

textarea {
  outline: none;
}

.previewProgramPage #modal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 90vh;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  border-radius: 10px;
  z-index: 5;
  overflow-y: scroll;
}

.previewProgramPage #modal2 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 150px;
  background-color: white;
  border-radius: 10px;
  z-index: 6;
}

.previewProgramPage #modal3 {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  /* height: 180px; */
  background-color: white;
  border-radius: 10px;
  z-index: 5;
  padding: 30px;
}

.previewProgramPage #equipmentBox {
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.previewProgramPage #weekDayBox {
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
