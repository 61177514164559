.loginPage{   
    width: 100%;
    background-color: #FAFAFD;
    display: flex;
}

.loginPage #leftSide {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aquamarine;
}

.loginPage #rightSide{
    width: 65%;
    display: flex;  
    justify-content: center;
    align-items: center; 
    background-color: #f5f5ff;
    list-style-type: none;
    
}

#loginBox {
    width: 500px;
    height: 350px;
    border-radius: 14px;   
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

#loginForm {
    width: 80%;
}

input::placeholder{
    color : #9E9EB4;
    opacity: 1;
    font-family: LexendRegular400;
    font-size: 14px;
}

#loginBox button{
    margin-top: 20px;
    /* padding-top: 15px;
    padding-bottom: 15px; */
    height: 40px;
    width: 100%;
    background-color: #F38CAC;
    border-style: none;
    border-radius: 9px;
    color: #ffffff;
    font-family: LexendSemiBold600;
    font-size: 16px;
    cursor: pointer;
}