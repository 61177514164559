.userProfilePage{
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    display: flex;
    list-style-type: none;
}

.userProfilePage .mainBody {
    width: 900px; 
    margin-left: 400px;
    background-color: white;
    margin-top: 60px;
    margin-bottom: 20px;
    /* border-style: solid; */
}

#buttonUserProfile{
    margin-top: 10px;
    margin-right: 20px;
    font-family: 'LexendSemiBold600';
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
    border-style: none;
}

 #modal{
    list-style-type: none;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px; 
    height: 320px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
    padding: 20px;
    font-family: 'LexendMedium500';
}

#modal2{
    list-style-type: none;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px; 
    height: 100px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
    padding: 20px;
    font-family: 'LexendMedium500';
}