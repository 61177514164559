.editProgramHomeClipPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    /* display: flex; */
    list-style-type: none;
}

.editProgramHomeClipPage .mainBody{
    width: 820px;
    margin-top: 50px;
    display: block;
    margin-left: 400px;
    margin-right: auto;
    /* background-color: #ffffff; */
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding-left: 30px; */
}

.editProgramHomeClipPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px; 
    /* height: 250px; */
    background-color: #FAFAFD;
    border-radius: 10px; 
    z-index: 5;
}

.editProgramHomeClipPage #modal2{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 680px; 
    height: 380px; 
    background-color: #FAFAFD;
    border-radius: 10px; 
    z-index: 5;
}

.editProgramHomeClipPage #modal3{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 200px;
    background-color: #FAFAFD;
    border-radius: 10px; 
    z-index: 5;
    font-family: 'LexendMedium500';
    font-size: 16px;
}

.editProgramHomeClipPage #modal4{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px; 
    padding: 30px;

    background-color: white;
    border-radius: 10px; 
    z-index: 4;
   
}

.clip-modal-exercise-wrapper{
    overflow-y: scroll;
    max-height: 500px;
}

