.userDetailsPage{
    width: 100%;
    height: 100%;
    background-color: #FAFAFD ;
    list-style-type: none;
}

.userDetailsPage .mainBody{
    width: 900px; 
    height: 100%;
    margin-left: 400px;
    display: flex;
    justify-content: space-between;
    
    
}

.userDetailsPage .mainBody input{    
    background-color: #ffffff;
    border-style: none;
}

input::placeholder{
    color : #9E9EB4;
    opacity: 1;
    font-family: 'LexendRegular400';
    font-size: 14px;
}