.blockedUserPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    display: flex;
    list-style-type: none;
}

.blockedUserPage .mainBody{
    width: 900px;
    margin-top: 50px;
    margin-left: 350px;
}

input:focus{
    outline: none;
}

table {
    border: 1px solid #D7D4E1;
    width: 890px;
    margin-left: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: white;
}


th{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FDF0F4;
    border-width: 0px;
    font-weight: 400;
}

button{
    cursor: pointer;
}

td{
    text-align: center;
    font-family: 'LexendRegular400';
    /* padding-bottom: 10px;
    padding-top: 10px; */
    /* height: 80px; */
    height: auto;
    margin-top: 13px;
    /* margin-bottom: 13px; */
    /* background-color: #FAFAFD; */   
}
.adminRow td{
    height: 80px;
}
tr{
    background-color: #FAFAFD; 
}
.blockedUserPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 150px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
}