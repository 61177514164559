.allAdminsPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    list-style-type: none;
}

.allAdminsPage .mainBody{
    width: 1000px;
    margin-top: 50px;
    display: block;
    margin-left: 400px;
    margin-right: auto;
    background-color: #ffffff;
}

td{
    font-family: 'LexendRegular400';
}

.allAdminsPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 150px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
}