.contactPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    display: flex;
    list-style-type: none;
}

.contactPage .mainBody{
    width: 900px;
    margin-top: 50px;
    display: block;
    margin-left: 400px;
    margin-right: auto;
}

.contactPage #equipmentBox {
    width: 150px;
    height: 120px;
    border-radius: 20px;
    background-color: #ffffff; 
    margin-right: 20px;
    margin-bottom: 30px; 
    -webkit-box-shadow: -4px 7px 10px -6px rgba(0,0,0,0.17);
    -moz-box-shadow: -4px 7px 10px -6px rgba(0,0,0,0.17);
    box-shadow: -4px 7px 10px -6px rgba(0,0,0,0.17);
}

.contactPage #uploadImageBox{
    width: 150px; 
    height: 150px;
    background: #ffffff;
    cursor: pointer;
}

.contactPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 150px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
}
