@font-face {
  font-family: "LexendRegular400";   /*Can be any text*/
  src: local("LexendRegular400"),
    url("./Fonts/LexendRegular400.ttf") format("truetype");
}

@font-face {
  font-family: "LexendMedium500";   /*Can be any text*/
  src: local("LexendMedium500"),
    url("./Fonts/LexendMedium500.ttf") format("truetype");
}

@font-face {
  font-family: "LexendSemiBold600";   /*Can be any text*/
  src: local("LexendSemiBold600"),
    url("./Fonts/LexendSemiBold600.ttf") format("truetype");
}

@font-face {
  font-family: "LexendBold700";   /*Can be any text*/
  src: local("LexendBold700"),
    url("./Fonts/LexendBold700.ttf") format("truetype");
}

@font-face {
  font-family: "LexendExtraBold800";   /*Can be any text*/
  src: local("LexendExtraBold800"),
    url("./Fonts/LexendExtraBold800.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'LexendRegular400', 'LexendMedium500', 'LexendMedium600', 'LexendBold700', 'LexendExtraBold800',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
