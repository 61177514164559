.resetForm{
    background-color: grey;
    margin: auto;
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    border-style: none;
}

.resetForm label{
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    color: white;
    font-weight: bold;
}

.resetForm button{
    display: block;
    width: 100px;
    height: 30px;
    margin: auto;
    margin-top: 20px;
    background-color:salmon;
    color: white;
    border-style: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
}

.resetForm input{
    padding: 5px;
    width: 250px;
}