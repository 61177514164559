.homePage{
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    display: flex;
    list-style-type: none;
}

.homePage .mainBody {
     width: 900px;  
    /* margin-left: 400px;  */
    
}