.editProgramDayDetailsPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    /* display: flex; */
    list-style-type: none;
}

.editProgramDayDetailsPage .mainBody{
    width: 820px;
    margin-top: 50px;
    display: block;
    margin-left: 400px;
    /* margin-right: auto; */
    /* background-color: #ffffff; */
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding-left: 30px; */
  
}

input::placeholder{
    color : #9E9EB4;
    opacity: 1;
    font-family: 'LexendRegular400';
    font-size: 14px;
}

textarea::placeholder{
    color : #9E9EB4;
    opacity: 1;
    font-family: 'LexendRegular400';
    font-size: 14px;
}

textarea{
    outline: none;
}

.editProgramDayDetailsPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 150px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
}

.editProgramDayDetailsPage #modal2{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px; 
    height: 250px; 
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 10px;
}

.editProgramDayDetailsPage #equipmentBox {
    -webkit-box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)

}


.cropper-modal-wrapper{
    width: 600px !important;
    /* height: 400px !important; */
   text-align: right;
   /* background-color: gray !important; */
   /* text-align: center; */
}
.croppper-body{
    /* width: 400px !important; */
    height: 370px !important;
    margin: auto;
    /* border: 2px solid black; */
}

.cropper-submit{
    border: none;
    font-size: 16px;
    padding: 12px 16px;
    background-color: rgb(243, 140, 172);
    color: white;
    font-weight: bold;
    font-family: LexendBold700;
    margin-top: 8px;
    margin-left: 12px;
}

.cropper-cancel-btn{
    border: none;
    font-size: 16px;
    padding: 12px 16px;
    background-color: rgb(243, 140, 172);
    color: white;
    font-weight: bold;
    font-family: LexendBold700;
    margin-top: 8px;
}
 #modal10{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: min-content !important;
    padding: 16px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
     box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;;
}