.categoriesPage {
  width: 100%;
  height: 100%;
  background-color: #fafafd;
  display: flex;
  list-style-type: none;
}

.categoriesPage .mainBody {
  width: 900px;
  margin-top: 50px;
  display: block;
  margin-left: 400px;
  margin-right: auto;
}

.categoriesPage #equipmentBox {
  width: 250px;
  height: 80px;
  border-radius: 20px;
  background-color: #ffffff;
  margin-right: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-box-shadow: -4px 7px 10px -6px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: -4px 7px 10px -6px rgba(0, 0, 0, 0.17);
  box-shadow: -4px 7px 10px -6px rgba(0, 0, 0, 0.17);
}

.categoriesPage #uploadImageBox {
  width: 150px;
  height: 150px;
  background: #ffffff;
  cursor: pointer;
}

.categoriesPage #modal {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 150px;
  background-color: white;
  border-radius: 10px;
  z-index: 5;
}
