.allProgramsPage {
    width: 100%;
    height: 100%;
    background-color: #FAFAFD;
    list-style-type: none;
}

.allProgramsPage .mainBody{
    width: 950px;
    margin-top: 50px;
    display: block;
    margin-left: 335px;
    background-color: #ffffff;
}

.allProgramsPage #modal{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px; 
    height: 150px;
    background-color: white;
    border-radius: 10px; 
    z-index: 5;
}